import React from 'react';
import './StorePolicyPage.css';

const StorePolicyPage = () => {
  return (
    <div className="policy-container">
      <h1>Store Policies</h1>
      
      <section>
        <h2>Return and Refund Policy</h2>
        <p>
          We offer a 14-day return policy. If you're not satisfied with your purchase, 
          please return the item within 30 days for a full refund. The product must be 
          in its original condition and packaging.
        </p>
      </section>

      <section>
        <h2>Shipping Information</h2>
        <p>
          We offer worldwide shipping with various shipping methods. Delivery times 
          vary depending on location. Please allow up to 7-14 business days for delivery.
        </p>
      </section>

      <section>
        <h2>Payment Methods</h2>
        <p>
          We accept all major credit cards, PayPal, and other secure payment options.
          Payment must be made in full before the order is processed.
        </p>
      </section>

      <section>
        <h2>Privacy Policy</h2>
        <p>
          We value your privacy and are committed to protecting your personal information. 
          Your data will not be shared with any third parties without your consent.
        </p>
      </section>

      <section>
        <h2>Terms of Service</h2>
        <p>
          By using our website, you agree to our terms and conditions. Please read 
          these carefully before making a purchase.
        </p>
      </section>
    </div>
  );
};

export default StorePolicyPage;
