import React, { useEffect, useState } from 'react';
import './CheckoutPage.css';

const PayPalButton = ({ totalAmount }) => {
  useEffect(() => {
    // Ensure PayPal button is only rendered once
    if (window.paypal && document.getElementById('paypal-button-container').childNodes.length === 0) {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: totalAmount, // Total amount for the cart
              },
            }],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            alert('Transaction completed by ' + details.payer.name.given_name);
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout Error:', err);
          alert('Payment failed! Please try again.');
        }
      }).render('#paypal-button-container');
    }
  }, [totalAmount]);

  return <div id="paypal-button-container"></div>;
};

const CheckoutPage = ({ cart }) => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');

  // Calculate the total amount for the cart
  const getTotalAmount = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <div className="checkout-container">
      <h1>Checkout</h1>
      <form className="checkout-form">
        <div className="form-group">
          <label>Full Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Shipping Address</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
      </form>

      {/* PayPal Section */}
      <div className="paypal-section">
        <h2>Pay with PayPal</h2>
        <PayPalButton totalAmount={getTotalAmount()} />
      </div>

      {/* Display cart summary */}
      <div className="cart-summary">
        <h2>Order Summary</h2>
        {cart.length === 0 ? (
          <p>No items in the cart.</p>
        ) : (
          cart.map((item) => (
            <div key={item.id}>
              <p>{item.name} - Quantity: {item.quantity} - Price: {item.price}</p>
            </div>
          ))
        )}
        <p><strong>Total: ${getTotalAmount()}</strong></p>
      </div>
    </div>
  );
};

export default CheckoutPage;
