import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProductListPage from './pages/ProductListPage';
import ProductDetailPage from './pages/ProductDetailPage';
import CartPage from './pages/CartPage';
import AdminPage from './pages/AdminPage';
import CheckoutPage from './pages/CheckoutPage';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import LoginPage from './pages/LoginPage';
import StorePolicyPage from './pages/StorePolicyPage';



function App() {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart)); // Persist cart to localStorage
  }, [cart]);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      if (existingProduct) {
        return prevCart.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };


  const handleBuyNow = (product) => {
    setCart([{ ...product, quantity: 1 }]); // Replace cart with the single item to buy now
    window.location.href = "/checkout"; // Redirect to checkout page
  };

  return (
    <Router>
      <Header />
      <main className="main-content" style={{ minHeight: '80vh' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductListPage />} />
          <Route path="/product/:id" element={<ProductDetailPage addToCart={addToCart} buyNow={handleBuyNow} />} />
          <Route
            path="/cart"
            element={<CartPage cart={cart} setCart={setCart} />} // Pass setCart function
          />
          <Route path="/checkout" element={<CheckoutPage cart={cart} />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/store-policy" element={<StorePolicyPage />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
