import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import './ProductListPage.css';
import { Slider } from '@mui/material';
import { FiMoreHorizontal } from 'react-icons/fi';  // Import icon for three dots

const ProductListPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [showFilters, setShowFilters] = useState(false); // For toggling additional filters

  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, 'products');
      const productSnapshot = await getDocs(productsCollection);
      const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
      setFilteredProducts(productList);
    };

    fetchProducts();

    // Check if there is a category in the URL query string
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get('category');
    if (category) {
      setSearchTerm(category); // Set the search term to the category
    }
  }, [location.search]);

  useEffect(() => {
    handleSearch();

    console.log('change');
    console.log('searchTerm:', searchTerm);

    setSearchTerm(searchTerm);
  }, [searchTerm, priceRange]);

  const handleSearch = () => {
    let filtered = products;

    // Filter by category or search term
    const categories = ['Scissor Lifts', 'Boom Lifts', 'Parts & Accessories'];
    if (categories.includes(searchTerm)) {
      filtered = filtered.filter(product =>
        product.category && product.category.toLowerCase() === searchTerm.toLowerCase()
      );
    } else if (searchTerm) {
      filtered = filtered.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (product.tags && product.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())))
      );
    }

    // Apply price range filter
    filtered = filtered.filter(product => product.price >= priceRange[0] && product.price <= priceRange[1]);

    setFilteredProducts(filtered);
  };

  return (
    <div className="product-list-container">
      <h1>Products</h1>

      <div className="search-filter-container">
        <input
          type="text"
          placeholder="Search by name, description, or tags"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-bar"
        />
        <button
          className="three-dot-button"
          onClick={() => setShowFilters(!showFilters)}
        >
          <FiMoreHorizontal />
        </button>
      </div>

      <div className={`additional-filters ${showFilters ? 'active' : ''}`}>
        <Slider
          value={priceRange}
          min={0}
          max={1000000}
          onChange={(e, newValue) => setPriceRange(newValue)}
          valueLabelDisplay="auto"
          className="price-slider"
        />
        <div className="price-range-labels">
          <span>Min: ${priceRange[0]}</span>
          <span>Max: ${priceRange[1]}</span>
        </div>
      </div>

      <div className="product-grid">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card">
            <Link to={`/product/${product.id}`}>
              <img
                src={product.imageURL}
                alt={product.name}
                loading="lazy"
                className="product-image"
              />
              <h3>{product.name}</h3>
              <p>${product.price}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductListPage;
