import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CartPage.css';

const CartPage = ({ cart, setCart }) => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState(cart);

  useEffect(() => {
    setCartItems(cart); // Update cart items when cart prop changes
  }, [cart]);

  const increaseQuantity = (productId) => {
    const updatedCart = cartItems.map((product) => {
      if (product.id === productId) {
        return { ...product, quantity: product.quantity + 1 };
      }
      return product;
    });
    setCartItems(updatedCart);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Persist in localStorage
  };

  const decreaseQuantity = (productId) => {
    const updatedCart = cartItems
      .map((product) => {
        if (product.id === productId) {
          return { ...product, quantity: product.quantity - 1 };
        }
        return product;
      })
      .filter((product) => product.quantity > 0); // Remove items with quantity 0
    setCartItems(updatedCart);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Persist in localStorage
  };

  const removeFromCart = (productId) => {
    const updatedCart = cartItems.filter((product) => product.id !== productId);
    setCartItems(updatedCart);
    setCart(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart)); // Persist in localStorage
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  return (
    <div className="cart-container">
      <h1>Your Cart</h1>
      {cartItems.length === 0 ? (
        <p>No items in the cart yet.</p>
      ) : (
        <div className="cart-items">
          {cartItems.map((product) => (
            <div key={product.id} className="cart-item">
              <div className="cart-item-image">
                <img src={product.imageURL} alt={product.name} />
              </div>
              <div className="cart-item-details">
                <h3>{product.name}</h3>
                <p className="cart-item-price">Price: ${product.price}</p>
                <p>Quantity: {product.quantity}</p>
                <div className="quantity-controls">
                  <button onClick={() => decreaseQuantity(product.id)}>-</button>
                  <button onClick={() => increaseQuantity(product.id)}>+</button>
                </div>
                <button className="remove-btn" onClick={() => removeFromCart(product.id)}>
                  Remove from Cart
                </button>
              </div>
            </div>
          ))}
          <button className="checkout-btn" onClick={handleCheckout}>
            Proceed to Checkout
          </button>
        </div>
      )}
    </div>
  );
};

export default CartPage;
