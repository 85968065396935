import React from 'react';
import "./Footer.css"


const Footer = () => {
  return (
    <footer style={{ background: "#333", padding: "10px", color: "#fff", textAlign: "center" }}>
      <p>&copy; 2024 Lift Marketplace. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
