import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './ProductDetailPage.css';

const ProductDetailPage = ({ addToCart, buyNow }) => {
  const { id } = useParams(); 
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productDoc = doc(db, 'products', id);
        const productData = await getDoc(productDoc);
        if (productData.exists()) {
          setProduct({ id: productData.id, ...productData.data() });
        } else {
          setProduct(null); 
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        setProduct(null);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity > 0 && newQuantity <= product.inventory) {
      setQuantity(newQuantity);
    }
  };

  if (loading) {
    return <h2>Loading product details...</h2>;
  }

  const discountedPrice = product.discount ? (product.price * (1 - product.discount / 100)).toFixed(2) : product.price;

  return product ? (
    <div className="product-detail-container">
      <div className="left-section">
        <div className="image-gallery">
          <img src={product.imageURL} alt={product.name} className="main-image" />
          {/* Additional thumbnails for more images */}
          <div className="image-thumbnails">
            {product.additionalImages && product.additionalImages.map((image, index) => (
              <img src={image} alt={`thumb${index}`} key={index} className="thumbnail" />
            ))}
          </div>
        </div>
        <div className="shipping-info">
          <h3>Shipping Information</h3>
          <p>Delivery: {product.shippingTime || 'Not Available'}</p>
          <p>Shipping Cost: {product.shippingCost ? `$${product.shippingCost}` : "Free"}</p>
          <p>Available Stock: {product.inventory}</p>
        </div>
      </div>

      <div className="product-info">
        <h1>{product.name}</h1>
        <p className="sku">SKU: {product.sku || 'N/A'}</p>
        <span className="price-info">
          {product.discount ? (
            <>
              <span className="original-price">${product.price}</span>
              <span className="discounted-price">${discountedPrice}</span>
            </>
          ) : (
            `$${product.price}`
          )}
        </span>

        <div className="quantity-section">
          <button onClick={() => handleQuantityChange(quantity - 1)}>-</button>
          <input type="text" value={quantity} readOnly />
          <button onClick={() => handleQuantityChange(quantity + 1)}>+</button>
        </div>

        <div className="action-section">
          <button className="add-to-cart" onClick={() => addToCart(product, quantity)}>
            Add to Cart
          </button>
          <button className="buy-now" onClick={() => buyNow(product, quantity)}>
            Buy Now
          </button>
        </div>

        <p className="product-description">{product.description}</p>
        <p className="tags">Tags: {product.tags ? product.tags.join(', ') : 'No tags available'}</p>

        <div className="product-reviews">
          <h3>Customer Reviews</h3>
          <p>No reviews yet. Be the first to review this product!</p>
        </div>
      </div>
    </div>
  ) : (
    <h2>Product not found</h2>
  );
};

export default ProductDetailPage;
