import React from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    navigate(`/products?category=${category}`); // Pass the category in the URL
  };

  return (
    <div className="home-container">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Welcome to the Lift Marketplace</h1>
          <p>Your one-stop shop for scissor lifts, boom lifts, and parts.</p>
          <button onClick={() => navigate('/products')} className="explore-button">
            Explore Our Products
          </button>
        </div>
      </section>

      {/* Featured Products Section */}
      <section className="featured-products">
        <h2>Featured Products</h2>
        <div className="product-grid">
          <div className="product-card">
            <img src="https://www.ofeklift.com/wp-content/webp-express/webp-images/uploads/2023/08/zahal_spider.png.webp" alt="Scissor Lift A" />
            <p>Spider Lift </p>
          </div>
          <div className="product-card">
            <img src="https://www.ofeklift.com/wp-content/uploads/2023/06/megdaley_teura_new.jpg" alt="Boom Lift B" />
            <p>Tower lights</p>
          </div>
          <div className="product-card">
            <img src="https://www.ofeklift.com/wp-content/webp-express/webp-images/uploads/2023/08/class_v_6.png.webp" alt="Parts Kit C" />
            <p>Forklift</p>
          </div>
        </div>
      </section>

      {/* Categories Section */}
      <section className="categories">
        <h2>Browse Categories</h2>
        <div className="category-grid">
          <div className="category-card" onClick={() => handleCategoryClick('Scissor Lifts')}>
            Scissor Lifts
          </div>
          <div className="category-card" onClick={() => handleCategoryClick('Boom Lifts')}>
            Boom Lifts
          </div>
          <div className="category-card" onClick={() => handleCategoryClick('Parts & Accessories')}>
            Parts & Accessories
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
