import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './AdminPage.css';

const AdminPage = () => {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState('add'); // Tab to toggle between adding products and managing inventory

  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState([]);
  const [shippingTime, setShippingTime] = useState('');
  const [shippingCost, setShippingCost] = useState(0);
  const [imageURL, setImageURL] = useState('');
  const [additionalImages, setAdditionalImages] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [newTag, setNewTag] = useState('');
  const [inventory, setInventory] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [editingStock, setEditingStock] = useState(0);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        fetchProducts();
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchProducts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleAdditionalImages = (e) => {
    const files = Array.from(e.target.files);
    setAdditionalImages(files);
  };

  const handleTagAddition = () => {
    if (newTag.trim()) {
      setTags((prevTags) => [...prevTags, newTag.trim()]);
      setNewTag('');
    }
  };

  const handleTagRemoval = (tagToRemove) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim() || isNaN(parseFloat(price)) || !description.trim()) {
      alert('Please fill in all required fields correctly.');
      return;
    }

    setIsUploading(true);

    let uploadedImageUrl = imageURL;
    let additionalImageUrls = [];

    if (imageFile) {
      const storageRef = ref(storage, `products/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile);
      uploadedImageUrl = await getDownloadURL(storageRef);
    }

    for (const file of additionalImages) {
      const storageRef = ref(storage, `products/additional/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      additionalImageUrls.push(url);
    }

    try {
      await addDoc(collection(db, 'products'), {
        name: name.trim(),
        sku: sku.trim(),
        price: parseFloat(price),
        description: description.trim(),
        imageURL: uploadedImageUrl,
        additionalImages: additionalImageUrls,
        shippingTime: shippingTime.trim(),
        shippingCost: parseFloat(shippingCost),
        tags: tags.map((tag) => tag.trim()),
        inventory: parseInt(inventory, 10),
        discount: parseFloat(discount),
      });
      alert('Product added successfully');
      setName('');
      setSku('');
      setPrice('');
      setDescription('');
      setTags([]);
      setShippingTime('');
      setShippingCost(0);
      setImageURL('');
      setImageFile(null);
      setAdditionalImages([]);
      setInventory(0);
      setDiscount(0);
      fetchProducts(); // Refresh products after adding
    } catch (error) {
      console.error('Error adding product:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setEditingStock(product.inventory);
  };

  const handleSaveEdit = async () => {
    if (!editingProduct) return;
    try {
      await updateDoc(doc(db, 'products', editingProduct.id), { inventory: editingStock });
      alert('Product updated successfully');
      setEditingProduct(null);
      fetchProducts();
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteDoc(doc(db, 'products', id));
        setProducts(products.filter((product) => product.id !== id));
        alert('Product deleted successfully');
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  return user ? (
    <div className="admin-container">
      <h1>Admin Dashboard</h1>
      
      {/* Tab Navigation */}
      <div className="admin-tabs">
        <button className={activeTab === 'add' ? 'active-tab' : ''} onClick={() => setActiveTab('add')}>
          Add Product
        </button>
        <button className={activeTab === 'manage' ? 'active-tab' : ''} onClick={() => setActiveTab('manage')}>
          Manage Inventory
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'add' && (
        <form onSubmit={handleSubmit} className="admin-form">
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>SKU (Product Code):</label>
            <input
              type="text"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Price:</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              className="form-control"
            ></textarea>
          </div>
          <div className="form-group">
            <label>Inventory:</label>
            <input
              type="number"
              value={inventory}
              onChange={(e) => setInventory(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Discount (%):</label>
            <input
              type="number"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Shipping Time:</label>
            <input
              type="text"
              value={shippingTime}
              onChange={(e) => setShippingTime(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Shipping Cost ($):</label>
            <input
              type="number"
              value={shippingCost}
              onChange={(e) => setShippingCost(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Tags:</label>
            <input
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              className="form-control"
            />
            <button type="button" onClick={handleTagAddition}>Add Tag</button>
            <div className="tags-container">
              {tags.map((tag) => (
                <span key={tag} className="tag">
                  {tag} <button onClick={() => handleTagRemoval(tag)}>x</button>
                </span>
              ))}
            </div>
          </div>
          <div className="form-group">
            <label>Upload Image:</label>
            <input
              type="file"
              onChange={handleFileChange}
              className="form-control"
              accept="image/*"
            />
          </div>
          <div className="form-group">
            <label>Upload Additional Images:</label>
            <input
              type="file"
              multiple
              onChange={handleAdditionalImages}
              className="form-control"
              accept="image/*"
            />
          </div>
          <button type="submit" className="submit-btn" disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Add Product'}
          </button>
        </form>
      )}

      {activeTab === 'manage' && (
        <div>
          <h2>Inventory Control</h2>
          <table className="product-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>SKU</th>
                <th>Price</th>
                <th>Inventory</th>
                <th>Discount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.sku || 'N/A'}</td>
                  <td>${product.price}</td>
                  <td>
                    {editingProduct && editingProduct.id === product.id ? (
                      <input
                        type="number"
                        value={editingStock}
                        onChange={(e) => setEditingStock(e.target.value)}
                        className="form-control"
                      />
                    ) : (
                      product.inventory
                    )}
                  </td>
                  <td>{product.discount ? `${product.discount}%` : 'N/A'}</td>
                  <td>
                    {editingProduct && editingProduct.id === product.id ? (
                      <button onClick={handleSaveEdit} className="save-btn">Save</button>
                    ) : (
                      <>
                        <button onClick={() => handleEdit(product)} className="edit-btn">Edit</button>
                        <button onClick={() => handleDelete(product.id)} className="delete-btn">Delete</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  ) : (
    <h1>Loading...</h1>
  );
};

export default AdminPage;
