import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCategoryClick = (category) => {
    navigate(`/products?category=${category}`);
  };

  return (
    <header>
      <nav>
        <div className="hamburger" onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
          <li><Link to="/">Home</Link></li>
          
          {/* Products Dropdown with Triangle Icon */}
          <li className="products-dropdown">
            <Link to="/products">
              Products <span className="triangle-icon">▼</span>
            </Link>

            {/* Dropdown Links */}
            <ul className="dropdown-menu">
              <li onClick={() => handleCategoryClick('Scissor Lifts')}>Scissor Lifts</li>
              <li onClick={() => handleCategoryClick('Boom Lifts')}>Boom Lifts</li>
              <li onClick={() => handleCategoryClick('Forklift')}>Forklift</li>
            </ul>
          </li>
          
          <li><Link to="/cart">Cart</Link></li>
          <li><Link to="/admin">LogIn</Link></li>
          <li><Link to="/store-policy">Store Policies</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
